<template>
  <div>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="招募人員代碼" name="first">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="UserName" label="帳號"> </el-table-column>
          <el-table-column prop="Name" label="姓名"> </el-table-column>
          <el-table-column prop="Code" label="招募人員代碼"> </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="測驗發布流水號" name="second">

      <el-form :inline="true" ref="recordDialog" :model="search" class="filter-block">
      <el-form-item label="日期起" prop="StartDate">
        <el-date-picker
          v-model="search.startDate"
          :picker-options="startPickerOptions"
          value-format="yyyy-MM-dd"
          type="date"
          :clearable="true"
          placeholder="請選擇"
        @change="handleStartDateChange"
        ></el-date-picker>
      </el-form-item>
      ~
      <el-form-item label="日期迄" prop="EndDate">
        <el-date-picker
          v-model="search.endDate"
          :picker-options="endPickerOptions"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="請選擇"
        @change="handleEndDateChange"
        ></el-date-picker>
      </el-form-item>

      <!-- <el-form-item label="關帳人員">
          <el-input v-model="search.UserKeyword" />
        </el-form-item> -->

      <el-form-item>
        <el-button type="primary" @click="getData('second')">查詢</el-button>
      </el-form-item>
    </el-form>

        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="UserName" label="帳號"> </el-table-column>
          <el-table-column prop="Name" label="姓名"> </el-table-column>
          <el-table-column prop="Date" label="日期">
            <template slot-scope="props">
            {{ dayjs(props.row.Date).format("YYYY-MM-DD") }}
          </template>
          </el-table-column>
          <el-table-column prop="SeriesNo" label="流水號"> </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="虛擬工號流水號" name="third">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="UserName" label="帳號"> </el-table-column>
          <el-table-column prop="Name" label="姓名"> </el-table-column>
          <el-table-column prop="SeriesNo" label="流水號"> </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// import { mapActions } from "vuex";

export default {
  data() {
    return {
      screen: null, // loading遮罩
      activeName: "first",
      tableData:[],
      search:{
        startDate:this.dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        endDate:this.dayjs().format("YYYY-MM-DD")
      },
      startPickerOptions: {
        disabledDate: (time) => {
          return this.search.endDate && time.getTime() > new Date(this.search.endDate).getTime();
        }
      },
      endPickerOptions: {
        disabledDate: (time) => {
          return this.search.startDate && time.getTime() < new Date(this.search.startDate).getTime();
        }
      }
    };
  },
  methods: {
    handleStartDateChange(value) {
      // 更新開始日期選擇器選項
      this.endPickerOptions = {
        disabledDate: (time) => {
          return value && time.getTime() < new Date(value).getTime();
        }
      };
    },
    handleEndDateChange(value) {
      // 更新結束日期選擇器選項
      this.startPickerOptions = {
        disabledDate: (time) => {
          return value && time.getTime() > new Date(value).getTime();
        }
      };
    },

    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    handleClick(tab) {
      console.log("tab",tab.name);
      this.getData(tab.name);
    },
    getData(type) {
      this.screen = this.openLoading();
      const vm = this;
      let url = null;
      let params = null;

      if(type==="first"){
        url = `${window.BaseUrl.api}/Eva/Release/Recruit/Code`;
      }else if(type==="second"){
        url = `${window.BaseUrl.api}/Eva/Release/Recruit/TestSeriesNo`;
        params={
          SDate:this.search.startDate,
          EDate:this.search.endDate,
        }
      }else{
        url = `${window.BaseUrl.api}/Eva/Release/Recruit/VirtualEmp`;
      }


      vm.$http.get(url,{params}).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: "錯誤",
            message: response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
        } else {
          vm.tableData = response.data.Data;
        }
        this.screen.close();
      });
    },


  },
  created() {
    this.getData('first');
  },
};
</script>
